#product {
  padding: 6rem 5vw;
  max-width: 1680px;
  margin: 0 auto;
}
#product h2 {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
}
#product .actionWrap .searchWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  font-size: 1.2rem;
  color: #888888;
  padding: .5rem 2rem;
  border: 2px solid #DDDDDD;
  border-radius: 10px;
  margin-top: 2rem;
}

#product .actionWrap .searchWrap .searchIcon{
  display:flex;

  width:40px; height:40px;

}
#product .actionWrap .searchWrap .searchIcon img{

  margin: -1px 0 0 -1px;
}



#product .actionWrap .searchWrap input {
  width: 100%;
  padding-right: 1rem;
  margin-right: 2rem;
  font-size: 1.25rem;
}
#product .actionWrap .searchWrap span {
  display: inline-block;
  border-radius: 100%;
  border: 2px solid #434343;
  scale: .8;
}
#product .actionWrap .searchWrap span img {
  display: block;
  scale: .6;
}



#product .categoryWrap * {
  font-weight: 600;
}
#product .categoryWrap{
  margin-top: 2rem;
}
#product .categoryWrap .categoryTitle{
  font-size: 1.75rem;
  font-weight: 700;
  border: 1px solid #DDDDDD;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1rem;
}
#product .categoryWrap .categoryList{
}
#product .categoryWrap .categoryList > li:last-child{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
#product .categoryWrap .categoryList > li{
  background-color: #F5F5F5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.2rem;
  border-bottom: 1px solid #DDDDDD;
}
#product .categoryWrap .categoryList > li .listTitle{
  width: 12vw;
  min-width: 100px;
  font-size: 1.3rem;
}
#product .categoryWrap .categoryList > li .listContent{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
#product .categoryWrap .categoryList > li:nth-child(1) .listContent li.active {
  background-color: #04416A;
  color: #F5F5F5;
  border: none;
}
#product .categoryWrap .categoryList > li:nth-child(2) .listContent li.active {
  background-color: #175F80;
  color: #F5F5F5;
  border: none;
}
#product .categoryWrap .categoryList > li:nth-child(3) .listContent li.active {
  background-color: #35949C;
  color: #F5F5F5;
  border: none;
}
#product .categoryWrap .categoryList > li:nth-child(4) .listContent li.active {
  background-color: #2A7D96;
  color: #F5F5F5;
  border: none;
}
#product .categoryWrap .categoryList > li:nth-child(5) .listContent li.active {
  background-color: #308ca8;
  color: #F5F5F5;
  border: none;
}
#product .categoryWrap .categoryList > li .listContent li{
  color: #666;
  cursor: pointer;
  border: 1px solid #666666;
  border-radius: 3rem;
  font-size: 1rem;
  padding: .5rem 1rem;
}


.displayWrap .sequence{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4vw;
  text-align: center;
  margin-top: 3rem;
  padding: 1rem;
  border-bottom: 1px solid #DDDDDD;
}
.displayWrap .sequence li.active {
  color: #434343;
}
.displayWrap .sequence li.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0; /* 텍스트에서 선까지의 거리 조정 */
  width: 100%;
  height: 3px; /* 선의 두께 */
  background: linear-gradient(to right, #034069, #55BFC8);
}
.displayWrap .sequence li{
  position: relative;
  color: #888888;
  padding-bottom: .5rem;
  cursor: pointer;
}


.displayWrap .cubeInfo{
  margin-top: 5vh;
}
.displayWrap .cubeInfo > p{
  font-size: 2rem;
  margin-bottom: 1rem;
}
.displayWrap .cubeInfo > ul{
  gap: 2%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.displayWrap .cubeInfo > ul > li{
  width: calc(94% / 4);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #BBBBBB;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  padding: 1rem;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, .1);
  cursor: pointer;
}
.displayWrap .cubeInfo > ul > li:first-child{
  display: flex;
  align-items: flex-start;
}
.displayWrap .cubeInfo > ul > li .infoImg {
  border-radius: 10px;
  margin-bottom: 1rem;
}
.displayWrap .cubeInfo > ul > li .infoImg img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.displayWrap .cubeInfo > ul > li .textWrap .titleTxt {
  margin-bottom: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: .3rem;
}

.displayWrap .cubeInfo > ul > li .textWrap .titleTxt .title {
  font-weight: 600;
  font-size: 1rem;
  color: #222;
  margin-right: 1rem;
}
.displayWrap .cubeInfo > ul > li .textWrap .titleTxt li:first-child{
  width:100%;
  display: -webkit-box;        /* Flexbox 컨테이너로 설정 */
  -webkit-box-orient: vertical; /* 세로 방향 설정 */
  overflow: hidden;            /* 넘치는 텍스트 숨김 */
  -webkit-line-clamp: 1;       /* 최대 줄 수 설정 (여기서는 2줄) */
  margin-right:1rem;
}


.displayWrap .cubeInfo > ul > li .textWrap .titleTxt li {
  font-size: .9rem;
  color: #434343;
}
.displayWrap .cubeInfo > ul > li .textWrap .infoTxt {
  text-align: start;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  color: #434343;
  font-size: 0.9rem;
  line-height: 1.5;
}
.displayWrap .cubeInfo > ul > li .textWrap .infoTxt .ellipsis{
  display: -webkit-box;        /* Flexbox 컨테이너로 설정 */
  -webkit-box-orient: vertical; /* 세로 방향 설정 */
  overflow: hidden;            /* 넘치는 텍스트 숨김 */
  -webkit-line-clamp: 2;       /* 최대 줄 수 설정 (여기서는 2줄) */
  line-height: 1.5;            /* 줄 간격 설정 */
  font-size: 0.9rem;
  font-weight: 300;
  max-height: calc(1.5em * 2); 
  margin-bottom:0.5rem;
}
.displayWrap .cubeInfo > ul > li .textWrap{
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 50%;
  width: 100%;
}
.displayWrap .cubeInfo > ul > li .textWrap p img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.displayWrap .cubeInfo > ul > li .textWrap span{
  margin-bottom: 1.5rem;
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
}
.page {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page p {
  cursor: pointer;
}
.page .prev {}
.page .next {}
.page .pagination {
  display: flex;
}
.page .pagination li {
  cursor: pointer;
}



#product .actionWrap .searchWrap span img {}

@media screen and (max-width:1024px){
  .displayWrap .cubeInfo > ul > li{
    width: calc(96% / 3);
  }
}
@media screen and (max-width:768px){
  #product h2 {
    font-size: 10vw;
  }
  .displayWrap .cubeInfo > ul > li{
    width: calc(98% / 2);
  }
}
@media screen and (max-width:500px){
  .displayWrap .cubeInfo > ul > li{
    width: 100%;
  }
}